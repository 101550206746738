<template>
  <div></div>
</template>

<script>
import { signOut } from "firebase/auth"
import { auth } from "@/firebase"
import VueCookie from "vue-cookie"
export default {
  name: 'Logout',
  created() {
    VueCookie.delete("microsoftToken")
    VueCookie.delete("slackToken")
    signOut(auth).then(() => {
      this.$router.push({ name: 'Auth' })
    });
  }
}
</script>